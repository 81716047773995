.cd_btn {
	height: 48px;
	border-radius: 90px;
	cursor: pointer;
	display: flex;
	text-align: center;
	align-items: center;
	background: #fa2852;
	color: white;
	span {
		margin: 16px 24px;
	}
	&.secondary {
		background: #ffffff;
		border: 1px solid #fa2852;
		color: #fa2852;
		&:hover {
			background: #fa2852;
			color: #ffffff;
		}
	}
}
