.cd_header {
	display: flex;
	justify-content: space-between;
	z-index: 9999;
	padding: 25px 0;
	.cd_logo {
		cursor: pointer;
		height: 48px;
	}
	.cd_header_right {
		display: flex;
		align-items: center;
		img {
			margin-right: 25px;
		}
	}
	.cd_social {
		cursor: pointer;
	}
}

@media (max-width: 768px) {
	.cd_header {
		.cd_header_left {
			img {
				width: 80%;
			}
		}
	}
}
