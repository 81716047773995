.cd_contact {
	height: 484px;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	background-image: url('../assets/images/footer-image.png');
	.cd_contact_header {
		margin-top: 100px;
		font-weight: 600;
		font-size: 54px;
		line-height: 68px;
	}
	.cd_contact_text {
		margin-top: 16px;
		font-size: 20px;
		line-height: 32px;
	}
	.cd_contact_actions {
		display: flex;
		margin-top: 52px;
		width: 280px;
		justify-content: space-around;
		align-items: center;

		.cd_contact_logo {
			width: 70px;
			height: 70px;
			background: #ffffff;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			.cd_logo {
				width: 28px;
				height: 28px;
			}
		}
	}
	.cd_copy_right {
		.cr_logo {
			font-size: 10px;
			position: absolute;
			padding-left: 2px;
		}
	}
}

@media (max-width: 768px) {
	.cd_contact {
		.cd_contact_header {
			font-size: 24px;
			line-height: 68px;
		}
		.cd_contact_actions {
			.cd_contact_logo {
				margin-bottom: 32px;
			}
		}
	}
}
