.cd_main_content {
	display: flex;
	align-items: center;
	margin-top: 100px;
	.cd_main_content_left {
		width: 50%;
		.animate_left {
			width: 100%;
		}
		.cd_main_content_header {
			font-size: 54px;
			font-weight: bold;
			color: #000000;
			line-height: 78px;
		}
		.cd_main_content_details {
			margin-top: 24px;
			font-size: 16px;
			color: #353945;
			width: 590px;
			line-height: 32px;
		}
		.cd_main_content_actions {
			margin-top: 50px;
			display: flex;
			.cd_btn,
			img {
				width: 200px;
				margin-right: 24px;
			}
		}
		.cd_application {
			margin-top: 50px;
			display: flex;
			img {
				margin-right: 24px;
				width: 170px;
				cursor: pointer;
			}
		}
	}
	.cd_main_content_right {
		width: 50%;
		img {
			width: 100%;
		}
	}
}

@media (max-width: 1150px) {
	.cd_main_content {
		flex-direction: column-reverse;
		margin-top: 0px;
		.cd_main_content_left {
			width: 100%;
			text-align: center;
			align-items: center;
			display: flex;
			flex-direction: column;
			.cd_main_content_header {
				max-width: 100%;
				font-size: 28px;
				line-height: 52px;
			}
			.cd_main_content_details {
				max-width: 100%;
				margin: auto;
			}
		}
		.cd_main_content_right {
			width: 50%;
		}
		.cd_main_content_actions,
		.cd_application {
			flex-direction: column;
			align-items: center;
			margin-top: 32px;
			.cd_btn {
				margin-right: 0px;
				width: fit-content;
				margin-bottom: 20px;
			}
		}
		.cd_application {
			margin-top: 0px !important;
			img {
				margin-bottom: 20px;
			}
		}
	}
}
