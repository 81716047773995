.cd_roadmap {
	width: 100%;
	margin-top: 120px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	.animated {
		width: 100%;
	}
	.cd_roadmap_header {
		font-weight: 600;
		font-size: 48px;
		line-height: 32px;
	}
	hr {
		border-top: 1px solid #3772ff;
		z-index: 0;
		width: calc(100% - 2px);
		top: 158px;
		position: relative;
	}
	.cd_roadmap_details {
		display: flex;
		margin-top: 70px;
		width: 100%;
		justify-content: space-between;
		.cd_roadmap_item {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 360px;
			text-align: center;
			img {
				width: 70px;
				height: 70px;
			}

			.cd_roadmap_time {
				margin-top: 32px;
				font-weight: 600;
				font-size: 32px;
				line-height: 48px;
			}
			.cd_roadmap_title {
				font-weight: 600;
				font-size: 24px;
				line-height: 48px;
			}
			.cd_roadmap_content {
				font-size: 16px;
				line-height: 30px;
				color: #353945;
				padding: 0 25px;
			}
			.cd_roadmap_indicator {
				width: 15px;
				height: 15px;
				border-radius: 50%;
				background: #fbd2d3;
				margin-top: 10px;
				z-index: 1;
				&.active {
					background: #fa2852;
				}
			}
			&.current {
				background: radial-gradient(farthest-side, #fff1f1c2 69%, #fde4e500 103%, #ffffff00 70%);
			}
		}
	}
}

@media (max-width: 768px) {
	.cd_roadmap {
		margin-top: 60px;
		hr {
			display: none;
		}
		.cd_roadmap_header {
			font-size: 24px;
			line-height: 24px;
		}
		.cd_roadmap_details {
			align-items: center;
			flex-direction: column;
			.cd_roadmap_item {
				img {
					display: none;
				}
				.cd_roadmap_time {
					font-size: 24px;
					line-height: 48px;
				}
				.cd_roadmap_title {
					font-size: 20px;
					line-height: 32px;
				}
			}
		}
	}
}
