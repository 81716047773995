.cd_ecosystem {
	margin-top: 120px;
	width: 100%;
	.cd_ecosystem_container {
		margin: 0 74px;
		padding: 70px 0;
		background: linear-gradient(to right, #f8f8f8, #fafafa);
		border-radius: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 48px;
		.ecosystem_animate {
			display: flex;
		}
		.cd_ecosystem_content {
			width: 548px;

			.cd_ecosystem_header {
				font-weight: 600;
				font-size: 48px;
				line-height: 50px;
			}
			.cd_ecosystem_item {
				margin-top: 60px;
				.cd_ecosystem_item_header {
					font-weight: 500;
					font-size: 24px;
					line-height: 36px;
					letter-spacing: 0.01em;
					color: #1f1d2b;
				}
				.cd_ecosystem_item_content {
					margin-top: 24px;
					font-size: 20px;
					line-height: 36px;
					color: #777e91;
				}
			}
		}
		.phone_image {
			img {
				width: 100%;
			}
		}
	}
}

@media (max-width: 1920px) {
	.cd_ecosystem {
		.cd_ecosystem_container {
			.cd_ecosystem_content {
			}
			.phone_image {
			}
		}
	}
}

@media (max-width: 1440px) {
	.cd_ecosystem {
		.cd_ecosystem_container {
			.cd_ecosystem_content {
			}
			.phone_image {
			}
		}
	}
}

@media (max-width: 1366px) {
	.cd_ecosystem {
		.cd_ecosystem_container {
			text-align: center;
			flex-direction: column-reverse;
			.cd_ecosystem_content {
				width: 100%;
				padding: 0px 10px;
				text-align: center;
				margin-top: 62px;
				.cd_ecosystem_header {
					font-size: 24px;
					line-height: 24px;
				}
				.cd_ecosystem_item {
					.cd_ecosystem_item_header {
						font-size: 20px;
						line-height: 32px;
					}
				}
			}
			.phone_image {
				padding-right: 0px;
				padding-left: 0px;
				width: 80%;
				max-width: 560px;
			}
		}
	}
}

@media (max-width: 768px) {
	.cd_ecosystem {
		.cd_ecosystem_container {
			margin: 0 34px;
		}
	}
}
