.cd_about_us {
	margin-top: 75px;
	width: 100%;
	.cd_about_title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 580px;
		background: #fafafa;
		border-radius: 48px;
		margin: 0 74px;

		div {
			font-weight: 600;
			font-size: 48px;
			line-height: 32px;
			position: relative;
			bottom: 100px;
			left: 450px;
		}
	}
	.cd_about_us_details {
		display: flex;
		gap: 24px;
		position: relative;
		bottom: 270px;
		margin: 0 350px 0 416px;
		justify-content: center;
		.cd_about_us_details_item {
			background: #ffffff;
			width: 362px;
			padding: 48px 48px;
			border: 1px solid #3772ff;
			border-radius: 32px;
			margin-left: 10px;
			.cd_about_us_details_title {
				font-weight: 600;
				font-size: 32px;
				line-height: 48px;
			}
			.cd_about_us_details_content {
				padding-top: 48px;
				font-size: 16px;
				line-height: 30px;
				color: #777e91;
			}
		}
	}
}

@media (max-width: 1920px) {
	.cd_about_us {
		.cd_about_title {
			div {
				left: 350px;
			}
		}
		.cd_about_us_details {
			margin: 0 300px 0 396px;
		}
	}
}

@media (max-width: 1536px) {
	.cd_about_us {
		.cd_about_title {
			div {
				left: 100px;
			}
		}
		.cd_about_us_details {
			margin: 0 157px 0 162px;
		}
	}
}

@media (max-width: 991px) {
	.cd_about_us {
		margin-top: 100px;
		.cd_about_title {
			flex-direction: column;
			justify-content: normal;
			div {
				font-size: 24px;
				line-height: 24px;
				bottom: 45px;
				left: 0;
			}
			img {
				width: 305px;
				height: 305px;
			}
		}
		.cd_about_us_details {
			flex-direction: column;
			margin: 0;
			align-items: center;
			.cd_about_us_details_item {
				margin-top: 16px;
				margin-left: 0;
				.cd_about_us_details_title {
					font-size: 20px;
					line-height: 48px;
				}
			}
		}
	}
}
