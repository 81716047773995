.cd_key_features {
	margin-top: 80px;
	.cd_key_features_item {
		display: flex;
		flex-direction: row;
		align-items: center;
		img {
			width: 248px;
			height: 249px;
		}
		.cd_key_features_title {
			font-weight: 600;
			font-size: 32px;
			line-height: 40px;
		}
		.cd_key_features_content {
			font-size: 16px;
			line-height: 32px;
			padding-top: 16px;
		}
	}
}

@media (max-width: 768px) {
	.cd_key_features {
		margin-top: 40px;
		.cd_key_features_item {
			flex-direction: column;
			text-align: center;
			.cd_key_features_title {
				font-size: 24px;
				line-height: 24px;
			}
		}
	}
}
