.cd_casper_stats {
	display: flex;
	height: 182px;
	align-items: center;
	width: 100%;
	// background: linear-gradient(to right, #fbd2d3, #ede0e0);
	margin-top: 122px;
	background-image: url('../assets/images/stats-background.png');
	.cd_casper_stats_content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 120px;
		width: 100%;
		flex-wrap: wrap;
		.cd_casper_stat_item {
			.cd_casper_stat_label {
				font-size: 16px;
				line-height: 16px;
				color: #000000;
			}
			img {
				width: 48px;
				height: 48px;
			}
			.cd_casper_stat_value {
				display: flex;
				font-weight: 600;
				font-size: 32px;
				line-height: 48px;
				align-items: center;
				padding-top: 6px;

				.cd_casper_stat_sub_value {
					padding-left: 10px;
					font-size: 16px;
					line-height: 30px;
					&.negative {
						color: #e1372d;
					}
					&.positive {
						color: #83bd67;
					}
				}
			}
		}
	}
}

@media (max-width: 1440px) {
	.cd_casper_stats {
		.cd_casper_stats_content {
			padding: 0 16px;
		}
	}
}

@media (max-width: 991px) {
	.cd_casper_stats {
		background-image: url('../assets/images/stats-high.png');
		background-size: cover;
		height: 360px;
		margin-top: 60px;
		.cd_casper_stats_content {
			justify-content: center;
			.cd_casper_stat_item {
				width: 40%;
				margin-bottom: 32px;
				.cd_casper_stat_value,
				.cd_casper_stat_main_value {
					font-size: 22px;
					line-height: 48px;
				}
			}
		}
	}
}

// @media (max-width: 768px) {
// 	.cd_casper_stats {
// 		.cd_casper_stats_content {
// 			justify-content: normal;
// 		}
// 	}
// }

@media (max-width: 600px) {
	.cd_casper_stats {
		height: 660px;
		margin-top: 60px;
		.cd_casper_stats_content {
			padding: 0 16px;
			justify-content: center;
			.cd_casper_stat_item {
				width: 80%;
				margin-bottom: 32px;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}
}
